import { graphql, Link } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";
import React from "react";
import { PostInterface } from "../templates/post";

export const query = graphql`
  fragment PostEntryFragment on WordPress_Post {
    id
    title
    uri
    slug
    date
    content: excerpt
    author {
      name
      firstName
      lastName
    }
    categories {
      nodes {
        name
        slug
      }
    }
    featuredImage {
      altText
      sourceUrl
      imageFile {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;

interface PostProps {
  post: PostInterface;
  location: Location;
}

const PostEntry: React.FC<PostProps> = ({ post, location }) => {
  const excerptSize = 30;
  const postUrl = "/blog/" + post.slug;
  const postContentStripped = post.content.replace(/<[^>]+>/g, "");
  const postContentReduced = postContentStripped
    .split(" ")
    .splice(0, excerptSize)
    .join(" ");
  const postExcerpt =
    postContentStripped.split(" ").length > excerptSize
      ? postContentReduced + "..."
      : postContentReduced;

  return (
    <div>
      {post.featuredImage && (
        <div>
          <Link to={postUrl}>
            <Img
              alt={post.featuredImage?.imageFile.altText}
              fluid={post.featuredImage?.imageFile.childImageSharp.fluid}
              className="mb-3"
            />
            <span className="sr-only">{`Link to ${post.title}`}</span>
          </Link>
        </div>
      )}
      <div>
        <Link to={postUrl}>
          <h2 className="font-normal text-scarlet hover:text-darkgray">
            {post.title}
          </h2>
        </Link>
      </div>
      <div className="meta mb-2">
        <div className="author text-sm">
          By:{" "}
          {post.author.firstName
            ? post.author.firstName + " " + post.author.lastName
            : post.author.name}
        </div>
        {post.categories && (
          <div className="flex items-center">
            <span className="mr-1 text-sm">Category: </span>
            <ul className="flex">
              {post?.categories?.nodes?.map((category, index) => (
                <li key={category.name}>
                  {index !== 0 && <span className="ml-1 text-sm">| </span>}
                  <Link
                    to={`/blog/category/${category.slug}`}
                    className="text-scarlet hover:text-darkgray text-sm"
                  >
                    {category.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div>
        <p
          className="text-darkgray font-serif"
          dangerouslySetInnerHTML={{ __html: postExcerpt }}
        />
      </div>
    </div>
  );
};

export default PostEntry;
