import { graphql, Link } from "gatsby";
import React from "react";
import Layout from "../components/layout";
import PostEntry from "../components/postEntry";
import SEO from "../components/seo";
import { PostInterface } from "./post";

interface BlogProps {
  pageContext: {
    hasNextPage: boolean;
    pageNumber: number;
  };
  data?: {
    wordPress?: {
      posts: {
        nodes: [PostInterface];
      };
    };
  };
  location: Location;
}

const Blog: React.FC<BlogProps> = ({ pageContext, data, location }) => {
  const renderPreviousLink = () => {
    const { pageNumber } = pageContext;

    let previousLink: string = "";

    if (!pageNumber) {
      return null;
    } else if (1 === pageNumber) {
      previousLink = `/blog`;
    } else if (1 < pageNumber) {
      previousLink = `/blog/${pageNumber - 1}`;
    }

    return <Link to={previousLink}>Previous Posts</Link>;
  };

  const renderNextLink = () => {
    const { hasNextPage, pageNumber } = pageContext;

    if (hasNextPage) {
      return <Link to={`/blog/${pageNumber + 1}`}>Next Posts</Link>;
    } else {
      return null;
    }
  };

  const { pageNumber } = pageContext;
  const blogPageNumber = pageNumber ? ` Page ${pageNumber}` : ``;

  return (
    <Layout>
      <SEO title="Blog" canonical={location.href} />
      <div className="container mx-auto p-3">
        <h1 className="text-center">Blog</h1>
        <ul className="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-1 grid-flow-row gap-10">
          {data?.wordPress?.posts?.nodes.map(post => (
            <li
              className="bg-gray p-4 md:p-10 row-span-1 col-span-1 max-w-full shadow-lg rounded-md"
              role="listitem"
              key={post.id}
            >
              <PostEntry post={post} location={location} />
            </li>
          ))}
        </ul>
        {(renderPreviousLink() || renderNextLink()) && (
          <div className="pt-8 flex flex-row">
            <div>{renderPreviousLink()}</div>
            <div className="ml-auto">{renderNextLink()}</div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Blog;

export const query = graphql`
  query GET_POSTS($ids: [ID]) {
    wordPress {
      posts(where: { in: $ids }) {
        nodes {
          ...PostEntryFragment
        }
      }
    }
  }
`;
